<script setup lang="ts">
import type { IBookingInfo } from '~/layers/booking/types/realty';

interface Props {
    info: IBookingInfo; // Информация для списка
}

const props = withDefaults(defineProps<Props>(), {});

const { 
    getInfoBlocks,
} = useBookingSummary();


const isLoading = ref<boolean>(true);

const infoBlocks = computed(() => {
    return getInfoBlocks(props.info);
});

onMounted(async () => {
    isLoading.value = false;
});
</script>

<template>
    <div v-if="infoBlocks.length" class="info">
        <v-skeleton-loader
            v-if="isLoading"
            color="neutral04"
            class="loader"
            type="text, paragraph, text, paragraph, text, paragraph"
        />
        <template v-else>
            <div 
                v-for="block in infoBlocks"
                :key="block.title"
                class="info-block"
            >
                <div class="info-block__title">{{ block.title }}</div>
                <div class="info-block__items">
                    <div 
                        v-for="item in block.items"
                        :key="item.title"
                        class="item"
                    >
                        <div class="item-title" v-html="item.title"></div>
                        <div class="item-value">{{ item.value }}</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped lang="scss">
.loader {
    width: 100%;
}

.info {
    &-block {
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid $color-neutral06;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        &__title {
            font-family: $ff-bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
            text-transform: uppercase;
        }

        &__items {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            .item {
                width: calc(50% - 12px);

                @include responsive( 'mobile' ) {
                    width: 100%;
                }

                &-value {
                    margin-top: 12px;
                    font-family: $ff-bold;
                }
            }
        }
    }
}
</style>